<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {routerHelper} from "@/helpers/router-helper";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {required} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";

/**
 * Core Order Add Carrier Component
 */
export default {

  components: {
    Multiselect,
    Layout,
    PageHeader
  },

  data() {
    return {

      shopOrder: null,
      shopOrderProducts: null,
      productVariations: null,
      products: null,
      carrierOperators: null,
      carrierOperatorsNames: null,

      form: {
        operator: "",
        trackingNumber: "",
        shopOrderProductIds: []
      }

    }
  },

  validations: {
    form: {
      trackingNumber: { required },
      operator: { required }
    }
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('core.order.add-carrier.title'),
          active: true
        }
      ]
    },

    async loadShopOrder(params) {
      try {
        const orderId = params.orderId
        if (!orderId) {
          return
        }

        const result = await axios.get(`/shop-order/fetch`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "shopOrderId": orderId,
          }
        });

        this.shopOrder = result.data
        await this.loadOrderProducts()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadOrderProducts() {
      try {
        const json = JSON.stringify({
          "ids": [this.shopOrder.id]
        })

        const result = await axios.post(`/shop-order/product/fetch/by/shop-order`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.shopOrderProducts = result.data
        await this.loadProductVariationsByIds()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadProductVariationsByIds() {
      try {
        const productVariationIds = [];

        for (const product of this.shopOrderProducts) {
          productVariationIds.push(product.productVariationId);
        }

        const json = JSON.stringify({
          "ids": productVariationIds
        });

        const result = await axios.post(`/product/variation/list-by-ids`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.productVariations = new Map(result.data.map((obj) => [obj.id, obj]));
        await this.loadProducts()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadProducts() {
      try {
        const productIds = [...this.productVariations.values()].map(obj => obj.productId);

        const json = JSON.stringify({
          "ids": productIds
        });

        const result = await axios.post(`/product/list-by-ids`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.products = new Map(result.data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadCarrierOperators() {
      try {
        const result = await axios.get(`/carrier/order/operators`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.carrierOperators = result.data
        await this.loadCarrierOperatorsNames()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadCarrierOperatorsNames() {
      try {
        const result = await axios.get(`/carrier/order/operators/names`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.carrierOperatorsNames = new Map(result.data.operators.map((obj) => [obj.operator, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getCarrierOperator(operator) {
      if (!this.carrierOperatorsNames) {
        return null
      }

      return this.carrierOperatorsNames.get(operator) || null
    },

    getProductVariation(id) {
      if (!this.productVariations) {
        return null
      }

      return this.productVariations.get(id) || null
    },

    getProduct(id) {
      if (!this.products) {
        return null
      }

      return this.products.get(id) || null
    },

    selectAllProducts() {
      const ids = []
      for (const product of this.shopOrderProducts) {
        ids.push(product.id)
      }

      const sameIds = this.form.shopOrderProductIds.length === ids.length
          && this.form.shopOrderProductIds.every(item => ids.includes(item))
          && this.form.shopOrderProductIds.every(item => ids.includes(item));

      if (sameIds) {
        this.form.shopOrderProductIds = []
      } else {
        this.form.shopOrderProductIds = ids
      }
    },

    isSameProductIds() {
      const ids = []
      for (const product of this.shopOrderProducts) {
        ids.push(product.id)
      }

      return this.form.shopOrderProductIds.length === ids.length
          && this.form.shopOrderProductIds.every(item => ids.includes(item));
    },

    createOrderCarrier() {
      const shopOrderProduct = this.shopOrderProducts[0]
      if (!shopOrderProduct) {
        Swal.fire("Błąd", "Czy te zamówienie zawiera choć jeden produkt?", "error");
        return
      }

      if (this.form.shopOrderProductIds.length === 0) {
        Swal.fire("Błąd", "Wybierz przynajmniej jeden produkt", "error");
        return
      }

      const productVariation = this.getProductVariation(shopOrderProduct.productVariationId)
      if (!productVariation) {
        Swal.fire("Błąd", "Nie znaleziono wariacji produktu", "error");
        return
      }

      const product = this.getProduct(productVariation.productId)
      if (!product) {
        Swal.fire("Błąd", "Nie znaleziono produktu", "error");
        return
      }

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const json = JSON.stringify({
        "shopOrderId": this.shopOrder.id,
        "warehouseId": product.warehouseId,
        "carrierId": this.shopOrder.carrierId,
        "packageId": shopOrderProduct.packageId,
        "operator": this.form.operator,
        "trackingNumber": this.form.trackingNumber,
        "shopOrderProductIds": this.form.shopOrderProductIds
      })

      axios.post(`/carrier/order/create`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        Swal.fire("Sukces!", "Pomyślnie dodano nowego przewoźnika", "success").then(() => {
          window.location = "/dashboard/core/order/details?shopOrderId=" + this.shopOrder.id
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    }

  },

  async created() {
    try {
      const params = routerHelper.params();
      await this.loadShopOrder(params)
      await this.loadCarrierOperators()
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('core.order.add-carrier.title')"/>

    <template v-if="shopOrder && shopOrderProducts">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <p>Wybierz produkty dla których chcesz dodać niestandardowego przewoźnika:</p>

              <div v-for="(item, index) in shopOrderProducts" :key="index">
                <div class="custom-control custom-checkbox">
                  <input :value="item.id" v-model="form.shopOrderProductIds" type="checkbox" class="custom-control-input" :id="`check-${item.id}`" :class="form.shopOrderProductIds.includes(item.id) ? 'custom-control-label-selected' : ''" />
                  <label class="custom-control-label" :for="`check-${item.id}`">
                    {{ item.name }}
                  </label>
                </div>
              </div>

              <b-form-checkbox :value="isSameProductIds" @change="selectAllProducts" class="py-4">Zaznacz/Odznacz Wszystkie</b-form-checkbox>

              <div class="row">
                <div class="col-12 col-lg-2">
                  <label>Nazwa przewoźnika</label>
                  <multiselect
                      v-model="form.operator"
                      :options="carrierOperators && carrierOperatorsNames ? carrierOperators : []"
                      :custom-label="value => getCarrierOperator(value).name"
                      placeholder="Wybierz przewoźnika"
                      :class="{ 'is-invalid': $v.form.operator.$error }"
                      :show-labels="false"
                  />
                  <div v-if="!$v.form.operator.required" class="invalid-feedback">{{ $t('message.required')}}</div>
                </div>
              </div>

              <!-- TODO: wybierz rodzaj przewoźnika -->

              <div class="row">
                <div class="col-12 col-lg-2">
                  <label>Numer śledzenia przesyłki:</label>
                  <input type="text" v-model="form.trackingNumber" class="form-control" :class="{ 'is-invalid': $v.form.trackingNumber.$error }" />
                  <div v-if="!$v.form.trackingNumber.required" class="invalid-feedback">{{ $t('message.required')}}</div>
                </div>
              </div>

              <div class="py-3">
                <b-button variant="primary" @click="createOrderCarrier">Zapisz</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="text-center py-3">
        <h5>{{ $t('message.loading') }}</h5>
        <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
      </div>
    </template>

  </Layout>
</template>